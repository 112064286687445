import styled from 'styled-components';
import media from 'styled-query';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: #0e1c26;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;

  ${media.lessThan("425px")`
    justify-content: space-between;
    padding: 2rem;
  `}
  /* ${media.between("768px", "999px")`
    font-size: 1.2vw;
  `}
  ${media.between("900px", "1150px")`
    font-size: 1.2vw;
  `} */
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  max-width: 785px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.lessThan("767px")`
    flex-direction: column;
  `}

  ${media.lessThan("425px")`
    justify-content: flex-start;
  `}
  
`;

export const Temp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DivSkills = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DivLogo = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  max-width: 150px;
  min-width: 150px;
  /* width: 15vw; */
  ${media.lessThan("425px")`
    min-width: 100px;
    width: 25vw;
  `}
`;

export const Name = styled.div`
  max-width: 450px;
  width: 100%;
  ${media.lessThan("767px")`
    max-width: 350px;
  `}
  ${media.lessThan("425px")`
    width: 80%;
  `}
`;

export const Separator = styled.div`
  width: 5px;
  height: 250px;
  background: #00E7F9;
  border-radius: 5px;
  margin: 0 30px;

  ${media.lessThan("767px")`
    max-width: 300px;
    width: 100%;
    height: 5px;
    margin: 40px 0;
  `}

  ${media.lessThan("425px")`
    margin: 20px 0;
    height: 2px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;
  width: 100%;

  ${media.lessThan("425px")`
    max-width: 100%;
    flex: 1;
    justify-content: space-evenly;
  `}
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;

  ${media.lessThan("767px")`
    align-items: center;
  `}
`;

export const TextTitle = styled.h1`
  color: #00E7F9;
  font-size: 30px;

  ${media.lessThan("425px")`
    font-size: 30px;
  `}
`;

export const TextTitleSub = styled.h2`
  color: #0093DC;
  font-size: 25px;
`;

export const DivContacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const TextTemp = styled.h2`
  color: #0093DC;
  font-size: 25px;

  ${media.lessThan("425px")`
    font-size: 20px;
  `}

  ${media.lessThan("350px")`
    font-size: 15px;
  `}
`;