import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`

export const ItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px;
`

export const ItemLink = styled.a`
  display: flex;
  justify-content: space-between;
  color: #1fa1f2;
  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  margin: 5px;
  &:hover {
    color: #00E7F9;
    svg {
      fill: #00E7F9 !important;
      color: #00E7F9 !important;
    }
  }
`