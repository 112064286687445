import React from "react"

import Icons from "./Icons"
import links from "../../db/SocialLinks"

import * as S from "./styled"

const Contacts = () => (
  <S.Wrapper>
    <S.List>
      {links.map((link, i) => {
        const Icon = Icons[link.label]
        return (
          <S.Item key={i}>
            <S.ItemLink
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.ItemIcon>
                <S.ItemIconDiv>
                  <Icon />
                </S.ItemIconDiv>
                <S.ItemLabel>{link.label}</S.ItemLabel>
              </S.ItemIcon>
              <S.ItemValue>
                {link.value}
              </S.ItemValue>
            </S.ItemLink>
          </S.Item>
        )
      })}
    </S.List>
  </S.Wrapper>
)

export default Contacts
