import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import * as S from "./styled"

const Skills = () => {
  const {
    skillJavascript,
    skillTypescript,
    skillNode,
    skillReact,
    // skillGatsby,
    // skillNext,
    skillDocker,
  } = useStaticQuery(
    graphql`
      query {
        skillJavascript: file(relativePath: { eq: "skill-javascript.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        skillTypescript: file(relativePath: { eq: "skill-typescript.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        skillNode: file(relativePath: { eq: "skill-node.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        skillReact: file(relativePath: { eq: "skill-react.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        skillGatsby: file(relativePath: { eq: "skill-gatsby.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        skillNext: file(relativePath: { eq: "skill-next.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        skillDocker: file(relativePath: { eq: "skill-docker.png" }) {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <S.Wrapper>
      <S.ItemDiv>
        <Img
          alt="Javascript"
          title="Javascript"
          fixed={skillJavascript.childImageSharp.fixed}
        />
      </S.ItemDiv>
      <S.ItemDiv>
        <Img
          alt="Typescript"
          title="Typescript"
          fixed={skillTypescript.childImageSharp.fixed}
        />
      </S.ItemDiv>
      <S.ItemDiv>
        <Img
          alt="NodeJs"
          title="NodeJs"
          fixed={skillNode.childImageSharp.fixed}
        />
      </S.ItemDiv>
      <S.ItemDiv>
        <Img
          alt="ReactJs"
          title="ReactJs"
          fixed={skillReact.childImageSharp.fixed}
        />
      </S.ItemDiv>
      <S.ItemLink
        href="https://www.credential.net/63d51be9-e493-4a9a-9433-d7169c46ce5c#gs.m5hoay"
        title="label"
        target="_blank"
        rel="noopener noreferrer"
        >
        <Img
          alt="Docker"
          title="Docker"
          fixed={skillDocker.childImageSharp.fixed}
          />
      </S.ItemLink>
    </S.Wrapper>
  )
}

export default Skills
