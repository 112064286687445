import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.div`
  width: 100%;

  ${media.lessThan("large")`
    /* display: none; */
  `}
`

export const List = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-around;
  list-style: none;
  flex-direction: column;

  font-size: 18px;

  ${media.lessThan("425px")`
    font-size: 18px;
    padding: 6px 0;
  `}
`

export const Item = styled.li`
  width: 100%;
  margin-bottom: 7px;

  ${media.lessThan("425px")`
    margin-bottom: 15px;
  `}
`

export const ItemLink = styled.a`
  display: flex;
  justify-content: space-between;
  color: #1fa1f2;
  text-decoration: none;
  transition: color 0.5s;
  align-items: center;
  &:hover {
    color: #00E7F9;
    svg {
      fill: #00E7F9 !important;
      color: #00E7F9 !important;
    }
  }
`
export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
`

export const ItemIconDiv = styled.div`
  /* Preenchimento Inicial */
  fill: #1fa1f2;
  width: 19px;
  /* height: 13px; */
  margin-right: 5px;

  ${media.lessThan("425px")`
    width: 24px;
  `}
`

export const ItemValue = styled.div`
  /* font-size: 18px; */
`

export const ItemLabel = styled.div`
  ${media.lessThan("374px")`
    display: none;
  `}
`